import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { loginUser } from '../services/authService';

const LoginPage = ({}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [enterpriseId, setEnterpriseId] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Create navigate function


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let data = await loginUser(username, password, enterpriseId)
      localStorage.setItem('authToken', data.access_token); // Store the token
      navigate('/'); // Navigate to home page
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="h-screen bg-gray-900 text-white flex justify-center items-center">
      <form onSubmit={handleLogin} className="w-1/4 bg-gray-800 p-6 rounded space-y-4">
        {error && <div className="text-red-500">{error}</div>}
        <div>
          <label htmlFor="username" className="block mb-2">Username</label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="block mb-2">Password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            required
          />
        </div>
        <div>
          <label htmlFor="id" className="block mb-2">Enterprise ID</label>
          <input
            id="enterprise_id"
            type="text"
            value={enterpriseId}
            onChange={(e) => setEnterpriseId(e.target.value)}
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-plato-purple p-2 rounded-md hover:opacity-75"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
