import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import CodeBlock from './CodeBlock';


let tempBuffer = '';

const ChatBot = ({spaceId}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [hasSentMessage, setHasSentMessage] = useState(false);
  const [streamFinished, setStreamFinished] = useState(false);

  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    scrollToBottom();
  }, [isLoading]);

  const updateLastMessage = (newText) => {
      setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          if(updatedMessages.length > 0) {
              updatedMessages[updatedMessages.length - 1].text = newText;
          }
          return updatedMessages;
      });
  };


  const sendMessage = async (e) => {
        e.preventDefault();
        if (input.trim() !== '') {
            setMessages([...messages, { text: input, sender: 'user' }]);
            // Here you can add the logic to process the message and generate a response

            const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage
            if (!token) {
                // Handle the case where the token is not available
                console.error('Token not found');
                return;
            }

            try {
                setIsLoading(true);
                setStreamFinished(false);

                setMessages(prevMessages => [
                  ...prevMessages, 
                  { text: '', sender: 'agent', isMarkdown: true }
                ]);

                const message = String(input);

                // Define the base URL based on the environment variable
                const baseUrl = process.env.REACT_APP_ENV === 'DEV' 
                  ? 'http://127.0.0.1:8000'
                  : 'https://platoportal.com';

                // Create a URL with query parameters
                const url = new URL(`${baseUrl}/api/v1/chat`);
                url.searchParams.append('space_id', spaceId);
                url.searchParams.append('message', message);
                url.searchParams.append('token', token); // Pass the token as a query parameter

                const eventSource = new EventSource(url.toString());

                eventSource.onmessage = function(event) {

                  tempBuffer += event.data;  // Append new data to the buffer
                  tempBuffer = tempBuffer.replace(/\\n/g, '\n'); // Replace '\\n' with newline character
                
                  updateLastMessage(tempBuffer); // Update the last message in the state
                };

                eventSource.onerror = function(error) {
                    setIsLoading(false);
                    setInput('');
                    setHasSentMessage(true);
                    eventSource.close();
                    tempBuffer = ''; // Clear the buffer after closing the connection
                    setStreamFinished(true);  // Set stream completion status
                    setMessages(prevMessages => prevMessages); // re-render the messages
                };

            } catch (error) {
                console.error('Error sending chat data:', error.message);
            }
        }   
    }

  const handleEscalate = () => {
    // Logic to handle escalation
  };

  return (
    <div className="h-full flex flex-col">
        <div className="flex-grow overflow-y-scroll scrollbar-custom h-96">
        {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
            <div className={`inline-block p-2 my-2 mx-3 rounded-lg leading-relaxed ${message.sender === 'user' ? 'bg-plato-purple text-white' : 'bg-gray-600 text-white'}`}>
                {message.isMarkdown ? (
                    <ReactMarkdown
                        remarkPlugins={[gfm]}
                        children={message.text}
                        components={{
                            code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            const isLastMessage = index === messages.length - 1;
                            return !inline && match && (!isLastMessage || streamFinished) ? (
                                <CodeBlock value={String(children).replace(/\n$/, '')} {...props} />
                            ) : (
                                <code className={className ? className : null} {...props}>
                                  {children} {/* Replace '\\n' with newline character */}
                                </code>
                            );
                            }
                        }}
                        />
                ) : (
                message.text
                )}
            </div>
            </div>
        ))}
        <div ref={messagesEndRef} />
        </div>

      {/* <div className="p-2 flex justify-end">
        {hasSentMessage && (
            <button 
            onClick={handleEscalate} 
            className="bg-red-600 text-white p-2 mt-2 rounded-lg"
            >
            Escalate
            </button>
        )}
      </div> */}
      <form onSubmit={sendMessage} className="flex p-2">
        <input 
          disabled={isLoading} // Disable input when loading
          type="text" 
          className={`flex-grow p-2 rounded-l-lg text-black bg-white border border-gray-300 focus:border-gray-500 focus:ring-0 focus:outline-none 
              ${isLoading ? 'bg-gray-200 text-gray-500' : ''}`} // Grey out when loading          value={input} 
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..." 
          value={input}
        />
        
        <button disabled = {isLoading} type="submit" className="bg-plato-purple text-white p-2 rounded-r-lg">
            {!isLoading ? 'Send' : (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
            )}
        </button>

      </form>
    </div>
  );
 
};


export default ChatBot;