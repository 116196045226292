import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import "swagger-ui-react/swagger-ui.css"
import './swagger-dark-theme.css'; // Import your custom theme after the default styles
import jsYaml from 'js-yaml';
import ChatBot from '../components/ChatBot';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import githubLogo from '../assets/github-logo.png';
import slackLogo from '../assets/slack-logo.png';
import { getSpace } from '../services/spaceService'


const SpaceView = () => {
  const [space, setSpace] = useState(null);
  const { id } = useParams();
  let navigate = useNavigate();


  useEffect(async () => {
    try {
      const data = await getSpace(id);
      setSpace(data);
    } catch (error) {
      console.error('Error fetching space data: ', error.message);
    }
  }, [id]);

  if (!space) {
    return <Loading/>;
  }

  const redirectToHome = () => {
    navigate('/'); // Navigate to Create Space page
  };

  return (
    <div className="h-full p-4 text-white bg-gray-900 overflow-y-auto flex flex-col">
      <div className="flex items-center mb-4 bg-gray-800 rounded-t-md">
        <div className="flex-shrink-0 mr-4">
          <button
            type="button"
            className="ml-2 bg-plato-blue p-2 rounded-md hover:opacity-75"
            onClick={redirectToHome}
          >
            Back to Search
          </button>
        </div>
        <h1 className="p-4 w-auto text-3xl flex-grow text-center">{space.name}</h1>
      </div>

      <div className="grid grid-cols-2 gap-4 flex-grow h-4/5">
        <div className="overflow-auto space-api">
          <SwaggerUI spec={jsYaml.load(space.openapi_spec)} />
          <div className="flex flex-col items-start space-y-4 w-full mt-4">
            <a href={space.github_repo} className="flex items-center text-blue-400 hover:text-blue-200 bg-gray-800 rounded p-2 w-full">
              <img src={githubLogo} alt="GitHub Logo" className="w-6 h-6 mr-2" />
              View in GitHub
            </a>
            <a href={space.slack_channel_id} className="flex items-center text-blue-400 hover:text-blue-200 bg-gray-800 rounded p-2 w-full">
              <img src={slackLogo} alt="Slack Logo" className="w-6 h-6 mr-2" />
              View in Slack
            </a>
          </div>
        </div>

        <div className="flex flex-col">
            <div className="relative mb-4 h-1/5 overflow-y-auto mb-8 scrollbar-custom">
                <div className="sticky top-0 z-10 bg-gray-800 p-4 rounded-t-md">
                    <h3 className="text-white">Relevant Documentation</h3>
                </div>
                <div className="overflow-y-auto p-4 bg-dark-background rounded-b-md scrollbar-custom">
                    {space.documentation_links.map((doc, index) => (
                    <div key={index} className="mb-2 p-2 bg-gray-800 rounded overflow-x-hidden">
                        <a href={doc} className="text-blue-400 hover:text-blue-200">{doc}</a>
                    </div>
                    ))}
                </div>
            </div>

            <div className="mt-2 chatbot bg-gray-800 p-4 h-3/4 mb-8">
                <h3 className="text-white">Support</h3>
                <ChatBot spaceId = {id}/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceView;