import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace'; // Ensure you have react-ace installed
import 'ace-builds/src-noconflict/mode-yaml'; // Import the required Ace editor modes
import 'ace-builds/src-noconflict/theme-monokai'; // Import the required Ace editor themes
import { createSpace, updateSpace } from '../services/spaceService';
import expandIcon from '../assets/expand.png';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-black p-4 rounded lg:max-w-6xl w-full h-auto">
          <button onClick={onClose} className="float-right text-xl font-bold text-white"> × </button>
          {children}
        </div>
      </div>
    );
}

const SpaceModal = ({ isEdit, space, isOpen, setOpen }) => {

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [openapiSpec, setOpenapiSpec] = useState('');
    const [githubRepo, setGithubRepo] = useState('');
    const [slackChannelId, setSlackChannelId] = useState('');
    const [newLink, setNewLink] = useState('');
    const [description, setDescription] = useState('');
    const [documentationLinks, setDocumentationLinks] = useState([]);
    const [errors, setErrors] = useState({});
    const [linkFormatWarning, setLinkFormatWarning] = useState('');
    const [modalOpen, setModalOpen] = useState(false);


    useEffect(() => {
        if (space) {
            setId(space.id);
            setName(space.name);
            setOpenapiSpec(space.openapiSpec);
            setGithubRepo(space.githubRepo);
            setSlackChannelId(space.slackChannelId);
            setDocumentationLinks(space.documentationLinks);
            setDescription(space.description);
        }
    }, [space]);

    const handleCreateSpace = async (e) => {
        e.preventDefault();
    
        setErrors({});
        
        let newErrors = {};
        if (!name) newErrors.name = 'Space name is required.';
        if (!openapiSpec) newErrors.openapiSpec = 'OpenAPI spec is required.';
        if (!githubRepo) newErrors.githubRepo = 'GitHub repository is required.';
        if (!slackChannelId) newErrors.slackChannelId = 'Slack Channel ID is required.';
        
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
        }
    
        try {
          const spaceData = {
            name: name,
            openapi_spec: openapiSpec,
            documentation_links: documentationLinks.filter(link => link.trim() !== ''),
            github_repo: githubRepo,
            slack_channel_id: slackChannelId,
          };
          await createSpace(spaceData);
          setOpen(false);
          setName('');
          setOpenapiSpec('');
          setDocumentationLinks([]);
          setGithubRepo('');
          setSlackChannelId('');
        } catch (err) {
          setErrors({ ...errors, submit: err.message });
        }
    };

    const handleUpdateSpace = async (e) => {
        e.preventDefault();
    
        setErrors({});
        
        let newErrors = {};
        if (!name) newErrors.name = 'Space name is required.';
        if (!openapiSpec) newErrors.openapiSpec = 'OpenAPI spec is required.';
        if (!githubRepo) newErrors.githubRepo = 'GitHub repository is required.';
        if (!slackChannelId) newErrors.slackChannelId = 'Slack Channel ID is required.';
        
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
        }
    
        try {
          const spaceData = {
            id: id,
            name: name,
            openapi_spec: openapiSpec,
            documentation_links: documentationLinks.filter(link => link.trim() !== ''),
            github_repo: githubRepo,
            slack_channel_id: slackChannelId,
            description: description
          };
          await updateSpace(id, spaceData);
          setOpen(false);
          setName('');
          setOpenapiSpec('');
          setDocumentationLinks([]);
          setGithubRepo('');
          setSlackChannelId('');
        } catch (err) {
          setErrors({ ...errors, submit: err.message });
        }
    };

    const handleModalSubmit = async (e) => {
        if (isEdit) {
            handleUpdateSpace(e);
            return;
        }
        handleCreateSpace(e);
    }

    const handleNewLinkChange = (e) => {
        setNewLink(e.target.value);
    };

    const addDocumentationLink = () => {
        // Check link format, for example using a regex
        if (isValidLink(newLink)) {
            setDocumentationLinks([...documentationLinks, newLink]);
            setNewLink('');
            setLinkFormatWarning('');
        } else {
            setLinkFormatWarning('Invalid link format. Please provide a valid Atlassian Confluence link.');
        }
    };

    const removeDocumentationLink = (index) => {
        setDocumentationLinks(documentationLinks.filter((_, i) => i !== index));
    };

    const isValidLink = (link) => {
        const confluenceLinkRegex = /^https?:\/\/[^\/]+\.atlassian\.net\/wiki\/.+/;
        if (link.trim() !== '') {
          return confluenceLinkRegex.test(link);
        }
        return false;
    };

    return (
        <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
            <form onSubmit={handleModalSubmit} className="space-y-4">
                {errors.submit && <div className="text-red-500">{errors.submit}</div>}
                <div>
                    <label htmlFor="name" className="block mb-2">Space Name</label>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-2 rounded-md bg-gray-700 text-white"
                    />
                    {errors.name && <p className="text-red-500">{errors.name}</p>}
                </div>
                <div className="relative">
                    <label className="block mb-2">OpenAPI Spec</label>
                    <AceEditor
                        mode="yaml"
                        theme="monokai"
                        name="openapiSpec"
                        onChange={setOpenapiSpec}
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={openapiSpec}
                        setOptions={{
                            useWorker: false,
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        height="200px"
                        width="100%"
                    />
                    <button onClick={() => setOpen(true)} style={{ backgroundColor: '#7828FF', padding: '5px', borderRadius: '5px', top: '40px', right: '10px', position: 'absolute' }}>
                        <img src={expandIcon} alt="Expand" className="h-6 w-6" />
                    </button>
                </div>
                {errors.openapiSpec && <p className="text-red-500">{errors.openapiSpec}</p>}
                <div>
                    <label htmlFor="githubRepo" className="block mb-2">GitHub Repository</label>
                    <input
                        id="githubRepo"
                        type="text"
                        value={githubRepo}
                        onChange={(e) => setGithubRepo(e.target.value)}
                        className="w-full p-2 rounded-md bg-gray-700 text-white"
                    />
                    {errors.githubRepo && <p className="text-red-500">{errors.githubRepo}</p>}
                </div>
                <div>
                    <label htmlFor="slackChannelId" className="block mb-2">Slack Channel ID</label>
                    <input
                        id="slackChannelId"
                        type="text"
                        value={slackChannelId}
                        onChange={(e) => setSlackChannelId(e.target.value)}
                        className="w-full p-2 rounded-md bg-gray-700 text-white"
                    />
                    {errors.slackChannelId && <p className="text-red-500">{errors.slackChannelId}</p>}
                </div>
                <div>
                    <label className="block mb-2">Documentation Links</label>
                    <div className="flex items-center space-x-2 mb-2">
                        <input
                            type="text"
                            value={newLink}
                            onChange={handleNewLinkChange}
                            placeholder="Add a new link"
                            className="w-full p-2 rounded-md bg-gray-700 text-white"
                        />
                        <button
                            type="button"
                            onClick={addDocumentationLink}
                            className="bg-plato-purple p-2 rounded-md hover:opacity-75"
                        >
                            Add
                        </button>
                    </div>
                    <div className="overflow-y-auto h-48">
                        {linkFormatWarning && <p className="text-red-500">{linkFormatWarning}</p>}
                        {documentationLinks.map((link, index) => (
                            <div key={index} className="flex items-center justify-between bg-gray-700 p-2 rounded-md mb-2">
                                <span className="break-all">{link}</span>
                                <button
                                    type="button"
                                    onClick={() => removeDocumentationLink(index)}
                                    className="bg-plato-purple px-2 rounded-md text-white hover:opacity-75"
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <button
                    type="submit"
                    className="w-full bg-plato-purple p-2 rounded-md hover:opacity-75"
                >
                    {isEdit ? "Update Space" : "Create Space"}
                </button>
            </form>
        </Modal>
    );
};

export default SpaceModal;
