import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Plato  from '../assets/plato.png';
import { searchSpace } from '../services/searchService'


const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [spaces, setSpaces] = useState([]);
  const [searched, setSearched] = useState(false);
  const navigate = useNavigate(); // Create navigate function

  const handleSearch = async (e) => {
    e.preventDefault();
    // Simulate a search result for demonstration
    const searchResult = await searchSpace(searchQuery)
    console.log(searchResult)
    setSpaces(searchResult.results);
    setSearched(true);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setSpaces([]);
    setSearched(false);
  };

  const redirectToManageSpaces = () => {
    navigate('/manage-spaces'); // Navigate to Create Space page
  };

  return (
    <div className="h-screen bg-gray-900 text-white overflow-y-auto">
      {/* Button positioned at the top right */}
      {!searched && (
        <div className="fixed top-4 right-4">
          <button
            type="button"
            className="bg-gray-600 p-2 rounded-md hover:bg-plato-blue"
            onClick={redirectToManageSpaces}
          >
            Manage Spaces
          </button>
        </div>
      )}

      {searched && (
      <div className="fixed bottom-4 left-4">
        <button
          type="button"
          className="bg-plato-blue p-2 rounded-md hover:opacity-75 "
          onClick={clearSearch}
        >
          Clear Search
        </button>
      </div>)}

      <div className={`container mx-auto p-4 ${searched ? 'pt-16 flex justify-center flex-col' : 'flex h-full items-center justify-center flex-col'}`}>
        {!searched && (
          <>
            <img src={Plato} alt="Plato" className="w-44 m-8" />
            <form onSubmit={handleSearch} className="w-full max-w-sm">
              <input
                type="text"
                className="w-full p-2 rounded-t-md focus:outline-none focus:ring-2 focus:ring-plato-purple focus:border-transparent text-black"
                placeholder="Find me a service that..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                type="submit"
                className="w-full bg-plato-purple p-2 rounded-b-md  hover:opacity-75"
              >
                Search
              </button>
            </form>
          </>
        )}
      </div>

      {searched && (
        <div className="container mx-auto p-4 overflow-y-auto">
          {spaces.map((space, index) => (
            <a href={`/space/${space.space_id}`} key={index}>
              <div className="bg-gray-800 p-4 rounded-md my-2">
                <h2 className="text-xl font-bold">{space.name}</h2>
                <p>{space.description}</p>
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
