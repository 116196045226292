const API_URL = process.env.REACT_APP_ENV === 'DEV' 
          ? 'http://127.0.0.1:8000/api/v1/search/' 
          : 'https://platoportal.com/api/v1/search/';

export const searchSpace = async (query) => {
    try {
        const response = await fetch(`${API_URL}?query=${query}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Error getting space:", error);
    }
};
