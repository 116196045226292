import React, { useState, useEffect } from 'react';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-monokai';
import { getSpaces, deleteSpace } from '../services/spaceService';
import { useNavigate } from 'react-router-dom';
import SpaceModal from '../components/SpaceModal';

const SpaceManagement = () => {

  const emptySpace = {
    id: "",
    name: "",
    openapiSpec: "",
    githubRepo: "",
    slackChannelId:"",
    documentationLinks:[]   
  }

  const [spaces, setSpaces] = useState([]);
  const [currentSpace, setCurrentSpace] = useState(emptySpace);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpaces = async () => {
      try {
        const response = await getSpaces();
        setSpaces(response || []);
      } catch (error) {
        console.error('Error fetching spaces:', error);
      }
    };

    fetchSpaces();
  }, [isAdding, isEditing]);

  useEffect(() => {
    console.log(currentSpace); // This will log the updated state
  }, [currentSpace]);

  const handleCreateSpace = () => {
    setCurrentSpace(emptySpace);
    setIsEditing(false);
    setIsAdding(true);
  }

  const handleEdit = (space) => {
    console.log(space);
    setCurrentSpace({
      id: space.id,
      name: space.name,
      openapiSpec: space.openapi_spec,
      githubRepo: space.github_repo,
      slackChannelId: space.slack_channel_id,
      documentationLinks:space.documentation_links,
      description:space.description
    });
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteSpace(id);
      setSpaces(spaces.filter(space => space.id !== id));
    } catch (error) {
      console.error('Error deleting space:', error);
    }
  };

  const redirectToResults = () => {
    navigate('/'); // Navigate to Create Space page
  };

  return (
    <div className="h-full p-4 text-white bg-gray-900 overflow-y-scroll flex flex-col">
      <div className="flex items-center mb-4 bg-gray-800 rounded-t-md">
        <div className="flex-shrink-0 mr-4">
          <button
            type="button"
            className="ml-2 bg-plato-blue p-2 rounded-md hover:opacity-75"
            onClick={redirectToResults}
          >
            Back to Search
          </button>
        </div>
        <h1 className="p-4 w-auto text-3xl flex-grow text-center">Space Management</h1>
      </div>

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl">Spaces</h2>
        <button onClick={() => handleCreateSpace()} className="bg-plato-purple p-2 rounded-md hover:opacity-75">
          Create New Space
        </button>
      </div>

      <table className="table-auto w-full bg-gray-800 rounded-md">
        <thead>
          <tr className="text-left bg-gray-700">
            <th className="p-2 text-white">Name</th>
            <th className="p-2"></th>
          </tr>
        </thead>
        <tbody>
          {spaces.map(space => (
            <tr key={space.id} className="text-white border-b rounded border-gray-700">
              <td className="text-white p-2">{space.name}</td>
              <td className="p-2 text-right">
                <button
                  onClick={() => handleEdit(space)}
                  className="bg-plato-purple text-white p-2 rounded-md hover:opaacity-75 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(space.id)}
                  className="text-white bg-red-600 p-2 rounded-md hover:bg-red-500"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <SpaceModal space={currentSpace} isEdit={true} isOpen={isEditing} setOpen={setIsEditing}/>
      <SpaceModal space={emptySpace} isEdit={false} isOpen={isAdding} setOpen={setIsAdding}/>
    </div>
  );
};

export default SpaceManagement;
