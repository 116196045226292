const API_URL = process.env.REACT_APP_ENV === 'DEV' 
          ? 'http://127.0.0.1:8000/api/v1/auth/' 
          : 'https://platoportal.com/api/v1/auth/';

export const loginUser = async (username, password, enterpriseId) => {
    try {
        let response = await fetch(`${API_URL}token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                'username': username, 
                'password': password,
                'enterprise_id' : enterpriseId
            }),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.log("Error logging in.")
    }
};

export const isAuthenticated = async (token) => {
    try {
      let response = await fetch(`${API_URL}status`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (response.status !== 200) {
        return false;
      }
      return true;
    } catch (error) {
      console.error('Error checking authentication status:', error);
      return false;
    }
  }
  