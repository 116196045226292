import React from 'react';

const LoadingDot = () => (
    <div className="w-6 h-6 bg-plato-purple rounded-full mx-1 animate-bounce" />
);

const Loading = () => (
    <div className="fixed inset-0 bg-gray-900 flex justify-center items-center">
        <LoadingDot />
        <LoadingDot />
        <LoadingDot />
    </div>
);

export default Loading;
