import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-monokai';

// CodeBlock component
const CodeBlock = ({ value }) => {
    const [editorHeight, setEditorHeight] = useState('40%');

    useEffect(() => {
        const lineHeight = 20; // Approximate line height in pixels
        const lines = value.split('\n').length;
        const newHeight = Math.max(lines * lineHeight, lineHeight); // Minimum height for at least one line
        setEditorHeight(`${newHeight}px`);
    }, [value]);

    return (
        <AceEditor
            key={value}
            mode="yaml"
            theme="monokai"
            name="chatResponse"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={value}
            setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: 2,
            }}
            width="100%"
            height={editorHeight}
        />
    );
};
  
export default CodeBlock;
