import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SearchPage from './views/SearchPage';
import SpaceView from './views/SpaceView';
import SpaceManagement from './views/SpaceManagement';
import LoginPage from './views/LoginPage';
import { isAuthenticated } from './services/authService';
import './tailwind.css';

function App() {
   const checkIsAuthenticated = async () => {
    const token = localStorage.getItem('authToken');
    return await isAuthenticated(token);
  };

  const PrivateRoute = ({ element: Component }) => {
    return checkIsAuthenticated() ? <Component /> : <Navigate to="/login" />;
  };

  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={<PrivateRoute element={SearchPage} />} 
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/space/:id"
          element={<PrivateRoute element={SpaceView} />}
        />
        <Route
          path="/manage-spaces"
          element={<PrivateRoute element={SpaceManagement} />}
        />
        {/* Redirect any other route to the SearchPage */}
        <Route 
          path="*" 
          element={<Navigate to="/" />} 
        />
      </Routes>
    </Router>
  );
}

export default App;